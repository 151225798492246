import {BookingStatus} from "./BookingComponent";

import './css/BookingStatusComponent.css'
import {RiCloseCircleLine} from "react-icons/ri";

export const BookingStatusComponent: React.FC<{bookingStatus:BookingStatus; closeAction: () => void}> = ({bookingStatus, closeAction }) => {
    let title;
    let content;
    let className;

    switch (bookingStatus){
        case BookingStatus.BOOKING_SUCCESSFUL:
            title = "Booking successful";
            content = "Your booking was successful. Please text me when you plan to cancel or change the time of your trip.";
            className = "booking-status-component-success";
            break;
        case BookingStatus.BOOKING_OVERLAPPED:
            title = "Booking failed";
            content = "Your selected timespan conflicted with another booking. Please choose a timespan with no interruptions.";
            className = "booking-status-component-failed";
            break;
        case BookingStatus.NOT_LOGGED_IN:
            title = "Booking failed";
            content = "You need to log in to be able to book.";
            className = "booking-status-component-failed";
            break;
        case BookingStatus.NOT_TWO_POINTS:
            title = "Booking failed";
            content = "You need to select two time-points to define when you want to book.";
            className = "booking-status-component-failed";
            break;
        default:
            title = "Unknown error";
            content = "There was an unknown error.";
            className = "booking-status-component-failed";
            break;
    }

    return (
        <div className={className + " booking-status-component-message"}>
           <div className={"booking-status-component-title"}>
               <div className={"booking-status-component-title-left"}>
                   <b>{title}</b>
               </div>
               <div className={"booking-status-component-title-right"}>
                   <RiCloseCircleLine onClick={closeAction}/>
               </div>
           </div>

            <>{content}</>
        </div>);
    }