import React, {useEffect} from 'react';
import './App.css';
import {BookingComponent} from "./components/BookingComponent";

function App() {
    useEffect(() => {
        document.title = "Zeitbuchung"
    }, [])

  return (
    <div className="App">
      <BookingComponent/>
    </div>
  );
}

export default App;
