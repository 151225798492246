
import {Booking} from "../types/booking";
import React from "react";

const BookingListItem : React.FC<{name: string, startDate:Date, endDate:Date}> = ({name, startDate,endDate}) => {
    return (
        <div>
            <h4>{name}</h4>
            <h5>{startDate.toDateString()} - ${endDate.toDateString()}</h5>
        </div>);
}

export const BookingListComponent: React.FC<{bookings : Array<Booking>}> = ({bookings }) => {
    return (
        <div className={'booking-overview-list'}>
            <p>Bookings:</p>
            {bookings
                .sort((a,b) => a.startDate.getTime()-b.startDate.getTime())
                .map(booking => <BookingListItem name={booking.name} startDate={booking.startDate} endDate={booking.endDate}/>)}
        </div>);
}