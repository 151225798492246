import firebase from "firebase/compat/app";

import {auth} from "../firebase-config";
import {useAuthState} from "react-firebase-hooks/auth";
import React from "react";

import { FcGoogle } from "react-icons/fc";
import {IoLogOutOutline} from "react-icons/io5"

import './css/AuthComponent.css';

const SignInComponent = () => {
    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider);
    }

    return (
        <div>
            <FcGoogle className={'icon-button'}  onClick={signInWithGoogle} size={32} style={{paddingTop:'10px',paddingBottom:'10px'}}/>
        </div>);
}

const SignOutComponent = () => {
    return auth.currentUser && (
        <IoLogOutOutline className={'icon-button'} onClick={() => auth.signOut()} size={64}/>
    )
}

export const AuthComponent = () => {
    const [user] = useAuthState(auth as any);

    return <div className={'auth-component'}>
        {user ?
            <>
                <div style={{width:'fit-content'}}>
                    <div style={{fontSize:'large'}}>{user.displayName}</div>
                </div>
                <SignOutComponent/>
            </>
            :
            <>
                <SignInComponent/>
            </>}
    </div>
}