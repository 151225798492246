import React, {useEffect, useMemo, useState} from "react";
import Calendar, {CalendarTileProperties} from "react-calendar";


import {addBooking, Booking, getAllBookings} from "../types/booking";
import {AuthComponent} from "./AuthComponent";
import {User} from 'firebase/auth/dist/auth';

import "./css/BookingComponent.css";
import "./css/Calendar.css"

import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase-config";
import {BookingListComponent} from "./BookingListComponent";
import {Button} from "react-bootstrap";
import {FcNext, FcPrevious} from "react-icons/fc";
import {BookingStatusComponent} from "./BookingStatusComponent";
import {ImBook} from "react-icons/im";

export enum BookingStatus {
    NONE,
    BOOKING_SUCCESSFUL,
    NOT_LOGGED_IN,
    BOOKING_OVERLAPPED,
    NOT_TWO_POINTS
}

const areBookingsOverlapped = (a:Booking, b:Booking):boolean => a.startDate.getTime()< b.endDate.getTime() && b.startDate.getTime()<a.endDate.getTime()

const getAllDatesBetween = (start:Date,end:Date):Array<Date> => {
    const dates :Array<Date> = [];
    for (let date = new Date(start); date<= end; date.setDate(date.getDate()+1)){
        dates.push(new Date(date))
    }
    return dates;
}

export const BookingComponent: React.FC = () => {
    const [bookings,setBookings] = useState<Array<Booking>>([])
    const [selectedDateSpan, setSelectedDateSpan] = useState<Array<Date>>([]);
    const [user] = useAuthState(auth as any);

    const [bookingStatus,setBookingStatus] = useState<BookingStatus>(BookingStatus.NONE);

    const isUserAdmin = user?.uid === 'rOsznYEv8FWeaSwlOfiy0KevDyt1';
    const isUserLoggedIn = !!user;

    const dateToInfoMap = useMemo(()=>{
        const dateInfo = new Map<number,string>();
        bookings.forEach(booking => getAllDatesBetween(booking.startDate,booking.endDate).forEach(date => dateInfo.set(date.getTime(),booking.name)))
        return dateInfo
    },[bookings])

    const book = async () =>{
        if (!isUserLoggedIn ){
            setBookingStatus(BookingStatus.NOT_LOGGED_IN);
            return;
        }
        if (!selectedDateSpan || selectedDateSpan.length !==2 ){
            setBookingStatus(BookingStatus.NOT_TWO_POINTS);
            return;
        }
        const newBooking = {name:(user as User).displayName||'PLACEHOLDER',startDate:selectedDateSpan[0], endDate:selectedDateSpan[1]};
        if(bookings.some(booking => areBookingsOverlapped(booking,newBooking)|| areBookingsOverlapped(newBooking,booking))){
            setBookingStatus(BookingStatus.BOOKING_OVERLAPPED);
            return;
        }
        const res = await addBooking(newBooking);
        if (res){
            setBookings([...bookings,newBooking])
            setBookingStatus(BookingStatus.BOOKING_SUCCESSFUL);
        }else{
            console.log('[73]: ',"ERROR");
        }

    }

    useEffect(() => {
        getAllBookings().then(setBookings)
    },[])


    const calculateCustomContent = (props: CalendarTileProperties) => {
        const time = props.date.getTime();
        if (isUserAdmin && dateToInfoMap.has(time)){
            return <p>{dateToInfoMap.get(time)}</p>
        }else{
            return <></>
        }
    }

    const calculateDisabled = (props: CalendarTileProperties) => {
        const time = props.date.getTime();
        return dateToInfoMap.has(time);
    }

    return (
        <div>
            <div className={'booking-header'}>
                <div className={'booking-header-left'}>
                    <h2>Time booking</h2>
                </div>
                <div className={'booking-header-right'}>
                    <AuthComponent/>
                </div>
            </div>
            <div className={'booking-content'}>
                <div className={'booking-picker'}>
                    <div className={'booking-calendar-wrapper'}>
                        <Calendar
                            selectRange={true}
                            onChange={setSelectedDateSpan}
                            next2Label={null}
                            nextLabel={<FcNext color={'#6c7293'}/>}
                            prevLabel={<FcPrevious color={'#6c7293'}/>}
                            prev2Label={null}
                            tileContent={calculateCustomContent}
                            tileDisabled={calculateDisabled}
                        />
                    </div>
                   <div className={'booking-divider'}/>

                    {isUserLoggedIn &&<div className={'booking-button'} onClick={book}><><ImBook/> Book </></div>}

                    {bookingStatus !== BookingStatus.NONE && <BookingStatusComponent bookingStatus={bookingStatus} closeAction={() => setBookingStatus(BookingStatus.NONE)}/>}
                </div>
            </div>
            {isUserAdmin && (
                <BookingListComponent bookings={bookings}/>
            )}
        </div>
    );
}