import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyD5IjUS7tZPH8gLmduuQSZP4P7yypk-qeQ",
    authDomain: "zeitbuchung-75987.firebaseapp.com",
    projectId: "zeitbuchung-75987",
    storageBucket: "zeitbuchung-75987.appspot.com",
    messagingSenderId: "89388283161",
    appId: "1:89388283161:web:ffc1dc82c3313b39d88188",
    measurementId: "G-HCYP2F8HCW"
}

const app = firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth(app);
export const firestore = firebase.firestore();
//export const analytics = firebase.analytics();