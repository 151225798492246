import {addDoc, collection, getDocs} from "firebase/firestore";
import {auth, firestore} from "../firebase-config";

export interface Booking {
    name: string;
    startDate: Date;
    endDate: Date
}

interface FirebaseBooking {
    userName: string;
    userId: string
    startTime: number;
    endTime: number
}

const bookingsCollection = collection(firestore,'bookings')

export const getAllBookings = async (): Promise<Array<Booking>> => {
    const bookingsSnapshot = await getDocs(bookingsCollection);
    return bookingsSnapshot.docs
        .map(doc => doc.data() as FirebaseBooking)
        .map(firebaseBooking => firebaseBookingConverter.fromFirestore(firebaseBooking));
}

export const addBooking = async (booking: Booking): Promise<FirebaseBooking|undefined> => {
    const firebaseBooking = firebaseBookingConverter.toFirestore(booking);
    await addDoc(bookingsCollection, firebaseBooking)
    return firebaseBooking;
}

const firebaseBookingConverter = {
    toFirestore: (booking:Booking):FirebaseBooking => {
        return {
            userName: booking.name,
            userId: auth.currentUser?.uid || 'FALLBACK',
            startTime: booking.startDate.getTime(),
            endTime: booking.endDate.getTime()
        };
    },
    fromFirestore: (firebaseBooking: FirebaseBooking): Booking => {
        return {
            name: firebaseBooking.userName,
            startDate: new Date(firebaseBooking.startTime),
            endDate: new Date(firebaseBooking.endTime),
        }
    }
};